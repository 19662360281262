// Apps:
const EVENTS = '/events/';
const CHALLENGE = '/challenge/';
const PLAYER = '/player/';
const CHOIRPLAYER = '/choirplayer/';
const BOOMWHACKERSPLAYER = '/boomwhackersplayer/';
const UKULELEPLAYER = '/ukuleleplayer/';
const GUITARPLAYER = '/guitarplayer/';
const WHITEPIANOPLAYER = '/whitepianoplayer/';
const BASSTABSPLAYER = '/basstabsplayer/';
const PIANONOTATIONPLAYER = '/pianonotationplayer/';
const BLACKJAZZPIANOPLAYER = '/blackjazzpianoplayer/';
const OPENTUNINGPLAYER = '/opentuningplayer/';
const SINGPLAYER = '/singplayer/';
const COMPOSER = '/composer/';
const ADMINPANEL = '/admin/';
const ACADEMY = '/academy/';
const PRIVACY = '/privacy';
const RESOURCES = '/resources/';
const SHOP = 'https://ec-play.shop/';
const ORG = 'https://ec-play.org';
const MEMBERINFO = 'https://www.allekanspille.no';
const SCHOOLONBOARDING = '/school/';

// Top-pages:
const ACCOUNT = '/account/';
const AUTH = '/auth/';
const CHAT = '/chats/';
const LANDING = '/';
const HUB = '/apps/';
const EDTECH = '/edtech/';
const ORGANIZATION = '/orgs/';
const REQUEST = '/requests/';
const EMAIL_SUBSCRIPTIONS = ACCOUNT + 'email-subscriptions';
const ABOUT = '/about/';
const REFERENCES = '/references/';
const PRICING = '/pricing/';
const PLAYER_DEMO = '/demo/';

export const EVENTS_RELATIVE_ROUTES = {
  HOME: '',
  EVENTS: 'all/',
  LOCATIONS: 'locations/',
  CREATE_EVENT: 'events/new/',
  CREATE_LOCATION: 'locations/new/',
  PROFILE: 'profile/',
  REQUEST: 'requests/',
  MEMBERSHIP: 'membership/',
  MEMBERSHIP_UPDATE: 'membership/update/',
  GUIDE: 'guide/',
};

export const EVENTS_ROUTES = {
  EVENTS: EVENTS + EVENTS_RELATIVE_ROUTES.HOME,
  EVENTS_EVENTS: EVENTS + EVENTS_RELATIVE_ROUTES.EVENTS,
  EVENTS_LOCATIONS: EVENTS + EVENTS_RELATIVE_ROUTES.LOCATIONS,
  EVENTS_CREATE_EVENT: EVENTS + EVENTS_RELATIVE_ROUTES.CREATE_EVENT,
  EVENTS_CREATE_LOCATION: EVENTS + EVENTS_RELATIVE_ROUTES.CREATE_LOCATION,
  EVENTS_PROFILE: EVENTS + EVENTS_RELATIVE_ROUTES.PROFILE,
  EVENTS_REQUEST: EVENTS + EVENTS_RELATIVE_ROUTES.REQUEST,
  EVENTS_MEMBERSHIP: EVENTS + EVENTS_RELATIVE_ROUTES.MEMBERSHIP,
  EVENTS_MEMBERSHIP_UPDATE: EVENTS + EVENTS_RELATIVE_ROUTES.MEMBERSHIP_UPDATE,
};

export const CHALLENGE_RELATIVE_ROUTES = {
  HOME: '',
  CHALLENGES: 'c/',
  CHALLENGES_NEW: 'c/new/',
  GROUPS: 'groups/',
  GROUPS_NEW: 'groups/new/',
  INVITE: 'invitation/',
  PROFILE: 'profile/',
};

export const CHALLENGE_ROUTES = {
  CHALLENGE: CHALLENGE + CHALLENGE_RELATIVE_ROUTES.HOME,
  CHALLENGE_CHALLENGES: CHALLENGE + CHALLENGE_RELATIVE_ROUTES.CHALLENGES,
  CHALLENGE_CHALLENGES_NEW: CHALLENGE + CHALLENGE_RELATIVE_ROUTES.CHALLENGES_NEW,
  CHALLENGE_GROUPS: CHALLENGE + CHALLENGE_RELATIVE_ROUTES.GROUPS,
  CHALLENGE_GROUPS_NEW: CHALLENGE + CHALLENGE_RELATIVE_ROUTES.GROUPS_NEW,
  CHALLENGE_INVITE: CHALLENGE + CHALLENGE_RELATIVE_ROUTES.INVITE,
  CHALLENGE_PROFILE: CHALLENGE + CHALLENGE_RELATIVE_ROUTES.PROFILE,
};

export const PLAYER_RELATIVE_ROUTES = {
  HOME: '',
  SONGS: 'songs/',
  PLAYLISTS: 'playlists/',
  SETTINGS: 'settings/',
  INVITE: 'invite/',
  SUBSCRIBE: 'subscribe/',
};

const makePlayerRoutes = (player_url: string) => {
  const player = player_url.split('/')[1];
  const capitalPlayer = player.toUpperCase();
  const playerRoutes: { [key: string]: string } = {};
  const relative_routes = Object.keys(PLAYER_RELATIVE_ROUTES) as (keyof typeof PLAYER_RELATIVE_ROUTES)[];
  for (let i = 0; i < relative_routes.length; i++) {
    let key = `${capitalPlayer}_${relative_routes[i]}`;
    if (relative_routes[i] === 'HOME') {
      key = capitalPlayer;
    }
    playerRoutes[key] = player_url + PLAYER_RELATIVE_ROUTES[relative_routes[i]];
  }
  return playerRoutes;
};

export const PLAYER_ROUTES = makePlayerRoutes(PLAYER) as {
  PLAYER: string;
  PLAYER_SONGS: string;
  PLAYER_PLAYLISTS: string;
  PLAYER_SETTINGS: string;
  PLAYER_INVITE: string;
  PLAYER_SUBSCRIBE: string;
};

export const CHOIRPLAYER_ROUTES = makePlayerRoutes(CHOIRPLAYER) as {
  CHOIRPLAYER: string;
  CHOIRPLAYER_SONGS: string;
  CHOIRPLAYER_PLAYLISTS: string;
  CHOIRPLAYER_SETTINGS: string;
  CHOIRPLAYER_INVITE: string;
  CHOIRPLAYER_SUBSCRIBE: string;
};

export const BOOMWHACKERSPLAYER_ROUTES = makePlayerRoutes(BOOMWHACKERSPLAYER) as {
  BOOMWHACKERSPLAYER: string;
  BOOMWHACKERSPLAYER_SONGS: string;
  BOOMWHACKERSPLAYER_PLAYLISTS: string;
  BOOMWHACKERSPLAYER_SETTINGS: string;
  BOOMWHACKERSPLAYER_INVITE: string;
  BOOMWHACKERSPLAYER_SUBSCRIBE: string;
};

export const UKULELEPLAYER_ROUTES = makePlayerRoutes(UKULELEPLAYER) as {
  UKULELEPLAYER: string;
  UKULELEPLAYER_SONGS: string;
  UKULELEPLAYER_PLAYLISTS: string;
  UKULELEPLAYER_SETTINGS: string;
  UKULELEPLAYER_INVITE: string;
  UKULELEPLAYER_SUBSCRIBE: string;
};

export const GUITARPLAYER_ROUTES = makePlayerRoutes(GUITARPLAYER) as {
  GUITARPLAYER: string;
  GUITARPLAYER_SONGS: string;
  GUITARPLAYER_PLAYLISTS: string;
  GUITARPLAYER_SETTINGS: string;
  GUITARPLAYER_INVITE: string;
  GUITARPLAYER_SUBSCRIBE: string;
};

export const WHITEPIANOPLAYER_ROUTES = makePlayerRoutes(WHITEPIANOPLAYER) as {
  WHITEPIANOPLAYER: string;
  WHITEPIANOPLAYER_SONGS: string;
  WHITEPIANOPLAYER_PLAYLISTS: string;
  WHITEPIANOPLAYER_SETTINGS: string;
  WHITEPIANOPLAYER_INVITE: string;
  WHITEPIANOPLAYER_SUBSCRIBE: string;
};

export const PIANONOTATIONPLAYER_ROUTES = makePlayerRoutes(PIANONOTATIONPLAYER) as {
  PIANONOTATIONPLAYER: string;
  PIANONOTATIONPLAYER_SONGS: string;
  PIANONOTATIONPLAYER_PLAYLISTS: string;
  PIANONOTATIONPLAYER_SETTINGS: string;
  PIANONOTATIONPLAYER_INVITE: string;
  PIANONOTATIONPLAYER_SUBSCRIBE: string;
};

export const BLACKJAZZPIANOPLAYER_ROUTES = makePlayerRoutes(BLACKJAZZPIANOPLAYER) as {
  BLACKJAZZPIANOPLAYER: string;
  BLACKJAZZPIANOPLAYER_SONGS: string;
  BLACKJAZZPIANOPLAYER_PLAYLISTS: string;
  BLACKJAZZPIANOPLAYER_SETTINGS: string;
  BLACKJAZZPIANOPLAYER_INVITE: string;
  BLACKJAZZPIANOPLAYER_SUBSCRIBE: string;
};

export const BASSTABSPLAYER_ROUTES = makePlayerRoutes(BASSTABSPLAYER) as {
  BASSTABSPLAYER: string;
  BASSTABSPLAYER_SONGS: string;
  BASSTABSPLAYER_PLAYLISTS: string;
  BASSTABSPLAYER_SETTINGS: string;
  BASSTABSPLAYER_INVITE: string;
  BASSTABSPLAYER_SUBSCRIBE: string;
};

export const OPENTUNINGPLAYER_ROUTES = makePlayerRoutes(OPENTUNINGPLAYER) as {
  OPENTUNINGPLAYER: string;
  OPENTUNINGPLAYER_SONGS: string;
  OPENTUNINGPLAYER_PLAYLISTS: string;
  OPENTUNINGPLAYER_SETTINGS: string;
  OPENTUNINGPLAYER_INVITE: string;
  OPENTUNINGPLAYER_SUBSCRIBE: string;
};

export const SINGPLAYER_ROUTES = makePlayerRoutes(SINGPLAYER) as {
  SINGPLAYER: string;
  SINGPLAYER_SONGS: string;
  SINGPLAYER_PLAYLISTS: string;
  SINGPLAYER_SETTINGS: string;
  SINGPLAYER_INVITE: string;
  SINGPLAYER_SUBSCRIBE: string;
};

export const COMPOSER_RELATIVE_ROUTES = {
  HOME: '',
  WITHOUT_PLAYBACK: 'private/',
};

export const COMPOSER_ROUTES = {
  COMPOSER: COMPOSER + COMPOSER_RELATIVE_ROUTES.HOME,
  COMPOSER_WITHOUT_PLAYBACK: COMPOSER + COMPOSER_RELATIVE_ROUTES.WITHOUT_PLAYBACK,
};
export const ADMINPANEL_RELATIVE_ROUTES = {
  HOME: '',
  PLAYER: PLAYER,
  EDITSONG: PLAYER + 'editsong/',
  CREATESONG: PLAYER + 'createsong/',
  EVENTS: EVENTS,
  SCHOOLONBOARDING: SCHOOLONBOARDING,
};

export const ADMINPANEL_ROUTES = {
  ADMINPANEL: ADMINPANEL + ADMINPANEL_RELATIVE_ROUTES.HOME,
  ADMINPANEL_PLAYER: ADMINPANEL + ADMINPANEL_RELATIVE_ROUTES.PLAYER,
  ADMINPANEL_EDITSONG: ADMINPANEL + ADMINPANEL_RELATIVE_ROUTES.EDITSONG,
  ADMINPANEL_EVENTS: ADMINPANEL + ADMINPANEL_RELATIVE_ROUTES.EVENTS,
  ADMINPANEL_SCHOOLONBOARDING: ADMINPANEL + ADMINPANEL_RELATIVE_ROUTES.SCHOOLONBOARDING,
};

export const SCHOOLONBOARDING_ROUTES = {
  SCHOOLONBOARDING_INTEREST: SCHOOLONBOARDING + 'interest/',
  SCHOOLONBOARDING_LICENSE: SCHOOLONBOARDING + 'license/',
};

export const ACADEMY_RELATIVE_ROUTES = {
  HOME: '',
  VIDEO: 'video/',
};

export const ACADEMY_ROUTES = {
  ACADEMY: ACADEMY + ACADEMY_RELATIVE_ROUTES.HOME,
  ACADEMY_VIDEO: ACADEMY + ACADEMY_RELATIVE_ROUTES.VIDEO,
};

export const RESOURCES_RELATIVE_ROUTES = {
  HOME: '',
};

export const RESOURCES_ROUTES = {
  RESOURCES: RESOURCES + RESOURCES_RELATIVE_ROUTES.HOME,
};

export const AUTH_RELATIVE_ROUTES = {
  SIGN_IN: '',
  SIGN_UP: 'sign-up/',
  FORGOT_PASSWORD: 'forgot-password/',
  FEIDE: 'feide/',
};

export const AUTH_ROUTES = {
  SIGN_IN: AUTH + AUTH_RELATIVE_ROUTES.SIGN_IN,
  SIGN_UP: AUTH + AUTH_RELATIVE_ROUTES.SIGN_UP,
  FORGOT_PASSWORD: AUTH + AUTH_RELATIVE_ROUTES.FORGOT_PASSWORD,
  FEIDE: AUTH + AUTH_RELATIVE_ROUTES.FEIDE,
};
export default {
  CHAT: CHAT,
  LANDING: LANDING,
  EDTECH: EDTECH,
  ACCOUNT: ACCOUNT,
  ORGANIZATION: ORGANIZATION,
  PRIVACY: PRIVACY,
  EMAIL_SUBSCRIPTIONS: EMAIL_SUBSCRIPTIONS,
  REQUEST: REQUEST,
  SHOP: SHOP,
  ORG: ORG,
  MEMBERINFO: MEMBERINFO,
  ABOUT: ABOUT,
  REFERENCES: REFERENCES,
  PRICING: PRICING,
  PLAYER_DEMO: PLAYER_DEMO,
  HUB: HUB,
  SCHOOLONBOARDING: SCHOOLONBOARDING,

  ...AUTH_ROUTES,

  ...EVENTS_ROUTES,
  ...CHALLENGE_ROUTES,
  ...PLAYER_ROUTES,
  ...CHOIRPLAYER_ROUTES,
  ...BOOMWHACKERSPLAYER_ROUTES,
  ...UKULELEPLAYER_ROUTES,
  ...GUITARPLAYER_ROUTES,
  ...WHITEPIANOPLAYER_ROUTES,
  ...PIANONOTATIONPLAYER_ROUTES,
  ...BLACKJAZZPIANOPLAYER_ROUTES,
  ...BASSTABSPLAYER_ROUTES,
  ...OPENTUNINGPLAYER_ROUTES,
  ...SINGPLAYER_ROUTES,
  ...COMPOSER_ROUTES,
  ...ADMINPANEL_ROUTES,
  ...ACADEMY_ROUTES,
  ...RESOURCES_ROUTES,
  ...SCHOOLONBOARDING_ROUTES,
};
